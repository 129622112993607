import { api } from 'api/admin'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Loader } from 'ui'
import { initLogger } from 'utils/remote-logger'
import { router } from './router'
import 'ui/index.scss'

initLogger(api.user.addUserListener, import.meta.env.VITE_LOGGER_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<RouterProvider router={router} fallbackElement={<Loader />} />)
