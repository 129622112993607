import { AdminUser, User } from 'api/admin'
import { adminRoutes } from 'const/admin-routes'
import { ownerRoutes } from 'const/owner-routes'

export const getHomeRoute = (user?: User | null): string => {
  if (!user) return adminRoutes.signUp
  if (AdminUser.hasRoleAdmin(user)) return adminRoutes.dashboard
  return ownerRoutes.dashboard
}

export const getFirstSignedInPage = (url: string) => {
  const { searchParams } = new URL(url)
  const nextParam = searchParams.get('next')
  if (!nextParam || EXCLUDED_ROUTES.includes(nextParam)) return undefined
  return nextParam
}

const EXCLUDED_ROUTES = [
  adminRoutes.signUp,
  adminRoutes.login,
  adminRoutes.passwordRecovery,
] as string[]
