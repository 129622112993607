import { FC } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Button, Description } from 'ui'
import styles from './error-page.module.scss'

interface Props {}

export const ErrorSubpage: FC<Props> = () => {
  const error = useRouteError()
  const isRouteResponse = isRouteErrorResponse(error)
  const message =
    (isRouteResponse && error.data?.message) || (error as Error)?.message || 'Unknown error'

  return (
    <section className={styles.subpage}>
      <Description error>
        <ul>
          <li className={styles.content}>
            <header>
              <h2>Oops</h2>
              {isRouteResponse && (
                <>
                  <h2>{error.status}</h2>
                  <p>{error.statusText}</p>
                </>
              )}
            </header>
            <p>{message}</p>
            <Button onClick={() => window?.location?.reload()} theme="warn">
              Refresh
            </Button>
          </li>
        </ul>
      </Description>
    </section>
  )
}
