import { AdminPage } from 'components/page'
import { useUser } from 'components/use-user'
import { adminRoutes } from 'const/admin-routes'
import { Navigate } from 'react-router-dom'
import { Loader } from 'ui'
import { getHomeRoute } from 'utils/get-home-route'

export default function WelcomePage() {
  const [user, loading] = useUser()
  return (
    <AdminPage noNav>
      <header>
        <h1>Agent Portal</h1>
      </header>
      {loading ? (
        <Loader />
      ) : !user ? (
        <Navigate to={adminRoutes.login} replace />
      ) : (
        <Navigate to={getHomeRoute(user)} replace />
      )}
    </AdminPage>
  )
}
