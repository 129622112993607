import { UserProvider } from 'components/use-user'
import { FC, useEffect } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { Outlet, Tooltip } from 'ui'
import { AuthProvider } from 'web-auth'
import { auth } from './auth'

export const Root: FC = () => {
  useEffect(injectStyle, [])
  return (
    <UserProvider>
      <AuthProvider auth={auth}>
        <Outlet />
      </AuthProvider>
      <ToastContainer theme="colored" />
      <ScrollRestoration />
      <Tooltip id={Tooltip.ID} />
    </UserProvider>
  )
}
