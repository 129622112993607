import {
  Unit,
  Application,
  Lease,
  UnitPhoto,
  Property,
  User,
  LeaseChecklist,
  Ledger,
  Template,
  PaymentAccount,
  UserDocument,
  LeaseDraft,
  UserSignature,
} from 'api/admin'
import { UNIT_PATHS } from 'const/unit-paths'
import { generatePath as gen } from 'react-router-dom'

const routes = {
  my: '/owner',

  // #region APPLICATIONS
  applications: '/owner/applications',
  applicationsEdit: 'edit/:application_id',
  getApplicationsEdit: (q: Application.Id) => gen(routes.applicationsEdit, q),
  applicationsCancel: 'cancel/:application_id',
  getApplicationsCancel: (q: Application.Id) => gen(routes.applicationsCancel, q),
  applicationsMove: 'move/:application_id',
  getApplicationsMove: (q: Application.Id) => gen(routes.applicationsMove, q),
  applicationsReport: 'report-view/:application_id',
  getApplicationsReport: (q: Application.Id) => gen(routes.applicationsReport, q),
  getApplicationsReportDownload: (q: Application.Id) =>
    `${gen(routes.applicationsReport, q)}?download`,
  // #endregion

  // #region APPLICATION
  applicationDetails: '/owner/applications/:application_id',
  getApplicationDetails: (q: Application.Id) => gen(routes.applicationDetails, q),
  applicationDetailsEdit: 'edit',
  applicationDetailsMove: 'move',
  applicationDetailsCancel: 'cancel',
  applicationDetailsDelete: 'delete',
  applicationDetailsReport: 'report',
  applicationDetailsReportDownload: 'report?download',
  applicationOverviewLeaseDraftCreate: 'create-draft',
  applicationOverviewLeaseDraftPreview: 'preview-draft/:draft_id',
  getApplicationLeaseDraftPreview: (query: LeaseDraft.Id) =>
    `${gen(routes.applicationOverviewLeaseDraftPreview, query)}`,
  // #endregion

  // #region DASHBOARD
  dashboard: '/owner/dashboard',
  // #endregion

  // #region INVITATIONS
  invitations: '/owner/invitations',
  getInvitations: (query: Record<string, string>) => withSearchParams(routes.invitations, query),
  // #endregion

  // #region LEASES
  leases: '/owner/leases',
  leasesEdit: 'edit/:lease_id',
  getLeasesEdit: (q: Lease.Id) => gen(routes.leasesEdit, q),
  leasesDelete: 'delete/:lease_id',
  getLeasesDelete: (q: Lease.Id) => gen(routes.leasesDelete, q),
  leaseContractView: 'contract-view/:lease_id',
  getLeaseContractView: (q: Lease.Id) => gen(routes.leaseContractView, q),
  leaseRenew: 'renew/:lease_id',
  getLeaseRenew: (q: Lease.Id) => gen(routes.leaseRenew, q),
  // #endregion

  // #region LEASE
  leaseDetails: '/owner/leases/:lease_id',
  getLeaseDetails: (q: Lease.Id) => gen(routes.leaseDetails, q),
  leaseDetailsRenew: 'renew',
  leaseDetailsRegenerate: 'regenerate-contract',
  leaseDetailsDelete: 'delete',
  leaseDetailsLock: 'lock',
  leaseDetailsUnlock: 'unlock',
  leaseDetailsDraftPreview: 'preview-draft/:draft_id',
  getLeaseDetailsDraftPreview: (q: LeaseDraft.Id) => gen(routes.leaseDetailsDraftPreview, q),
  leaseDetailsContractView: 'view-contract',
  leaseDetailsPhotoIdView: 'view-photo-id/:user_id',
  getLeaseDetailsPhotoIdView: (q: User.Id) => gen(routes.leaseDetailsPhotoIdView, q),
  leaseDetailsSign: 'sign',
  leaseDetailsSignatureEdit: 'edit-signature/:type',
  getLeaseDetailsSignatureEdit: (q: { type: UserSignature.Type }) =>
    gen(routes.leaseDetailsSignatureEdit, q),
  // LEASE DETAILS -> OVERVIEW
  leaseDetailsOverview: 'overview',
  // LEASE -> CHECKLIST
  leaseDetailsChecklist: 'checklist',
  leaseDetailsChecklistDelete: 'delete',
  leaseDetailsEditChecklist: 'checklist/:item_id',
  getLeaseDetailsEditChecklist: (query: LeaseChecklist.Id) =>
    gen(routes.leaseDetailsEditChecklist, query),
  // LEASE -> LEDGER
  leaseDetailsLedger: 'ledger',
  leaseDetailsLedgerCreateCharge: 'new-charge',
  leaseDetailsLedgerCreatePayment: 'new-payment',
  leaseDetailsLedgerDelete: 'delete/:transaction_id',
  getLeaseDetailsLedgerDelete: (q: Ledger.Id) => gen(routes.leaseDetailsLedgerDelete, q),
  leaseDetailsLedgerEdit: 'edit/:transaction_id',
  getLeaseDetailsLedgerEdit: (q: Ledger.Id) => gen(routes.leaseDetailsLedgerEdit, q),
  // LEASE -> INVOICE
  leaseDetailsInvoice: 'invoice',
  // #endregion

  // #region PAYMENTS
  payments: '/owner/payments',
  // #endregion

  // #region PAYMENT ACCOUNTS
  paymentAccounts: '/owner/payment-accounts',
  paymentAccountsCreate: 'new',
  paymentAccountsCreateFinix: 'new/finix',
  paymentAccountsEdit: 'edit/:owner_bank_account_id',
  getPaymentAccountsEdit: (q: PaymentAccount.Id) => gen(routes.paymentAccountsEdit, q),
  paymentAccountsDelete: 'delete/:owner_bank_account_id',
  getPaymentAccountsDelete: (q: PaymentAccount.Id) => gen(routes.paymentAccountsDelete, q),
  // #endregion

  // #region PROPERTIES
  properties: '/owner/properties',
  propertiesCreate: 'new',
  propertiesEdit: 'edit/:property_id',
  getPropertiesEdit: (q: Property.Id) => `${routes.properties}/${gen(routes.propertiesEdit, q)}`,
  propertiesDelete: 'delete/:property_id',
  getPropertiesDelete: (q: Property.Id) =>
    `${routes.properties}/${gen(routes.propertiesDelete, q)}`,
  // #endregion

  // #region PROPERTY
  property: '/owner/properties/:property_id',
  getPropertyDetails: (q: Property.Id) => gen(routes.property, q),
  propertyOverview: 'overview',
  propertyOverviewEdit: 'edit',
  propertyOverviewDelete: 'delete',
  propertyOverviewLocation: 'location',
  propertyOverviewFindLocation: 'find-location',
  getPropertyDetailsFindLocation: (q: Property.Id) =>
    `${routes.getPropertyDetails(q)}/${routes.propertyOverview}/${
      routes.propertyOverviewFindLocation
    }`,
  propertyUnits: 'units',
  propertyDetailsUnitEdit: 'edit/:unit_id',
  getPropertyDetailsUnitEdit: (q: Unit.Id) => gen(routes.propertyDetailsUnitEdit, q),
  propertyAuditLog: 'audit-log',
  // #endregion

  // #region TEMPLATES
  templates: '/owner/templates',
  templatesCreate: 'create',
  templatesViewPdf: 'view-pdf/:template_id',
  getTemplatesViewPdf: (q: Template.Id) => gen(routes.templatesViewPdf, q),
  templatesEdit: 'edit/:template_id',
  getTemplatesEdit: (q: Template.Id) => gen(routes.templatesEdit, q),
  templatesDelete: 'delete/:template_id',
  getTemplatesDelete: (q: Template.Id) => gen(routes.templatesDelete, q),
  templatesClone: 'clone/:template_id',
  getTemplatesClone: (q: Template.Id) => gen(routes.templatesClone, q),
  // #endregion

  // #region TEMPLATE
  templateDetails: '/owner/templates/:template_id',
  getTemplateDetails: (q: Template.Id) => gen(routes.templateDetails, q),
  templateDetailsOverview: 'overview',
  templateDetailsOverviewEdit: 'edit',
  templateDetailsOverviewClone: 'clone',
  templateDetailsOverviewViewPdf: 'view-pdf',
  templateDetailsOverviewDelete: 'delete',
  templateDetailsEditor: 'editor',
  getTemplateDetailsEditor: (q: Template.Id) =>
    `${gen(routes.templateDetails, q)}/${routes.templateDetailsEditor}`,
  // #endregion

  // #region UNITS
  units: '/owner/units',
  unitCreate: 'create',
  ...UNIT_PATHS,
  unitsImport: 'import',
  unitsEdit: 'edit/:unit_id',
  getUnitsEdit: (q: Unit.Id) => `${routes.units}/${gen(routes.unitsEdit, q)}`,
  unitsDelete: 'delete/:unit_id',
  getUnitsDelete: (q: Unit.Id) => `${routes.units}/${gen(routes.unitsDelete, q)}`,
  // #endregion

  // #region UNIT
  unitDetails: '/owner/units/:unit_id',
  getUnitDetails: (q: Unit.Id) => gen(routes.unitDetails, q),
  getUnitApplications: (q: Unit.Id) => `${gen(routes.unitDetails, q)}/${routes.unitApplications}`,
  // UNIT -> OVERVIEW
  unitDelete: 'delete',
  unitDetailsAddPhoto: 'add-photo',
  unitDetailsDelete: 'delete',
  unitDetailsDeletePhoto: 'gallery/:photo_id/delete',
  getUnitDetailsDeletePhoto: ({ photo_id }: UnitPhoto.Id) =>
    gen(routes.unitDetailsDeletePhoto, { photo_id }),
  unitDetailsDetails: 'details',
  unitDetailsEdit: 'edit',
  unitDetailsFindLocalion: 'find-location',
  unitDetailsImport: 'import',
  unitDetailsLocation: 'location',
  unitDetailsShare: 'details/share',
  unitDetailsViewPhoto: 'gallery/:photo_id',
  getUnitDetailsViewPhoto: (q: Unit.Id & UnitPhoto.Id) =>
    `${routes.getUnitDetails(q)}/${routes.unitDetailsDetails}/${gen(
      routes.unitDetailsViewPhoto,
      q,
    )}`,
  // UNIT -> AGENTS
  unitDetailsAgents: 'agents',
  getUnitDetailsAgents: (query: Unit.Id) =>
    `${routes.getUnitDetails(query)}/${routes.unitDetailsAgents}`,
  unitDetailsAgentsManage: 'manage',
  // UNTI -> AUCTION
  unitDetailsAuction: 'auction',
  unitDetailsAuctionArchive: 'archive',
  unitDetailsAuctionDelete: 'delete',
  unitDetailsAuctionEdit: 'edit',
  unitDetailsAuctionReopen: 'reopen',
  unitDetailsAuctionClose: 'close/:application_id',
  getUnitDetailsCloseAuction: (data: Application.Id) => gen(routes.unitDetailsAuctionClose, data),
  unitDetailsAuctionCancelApplication: 'cancel-application/:application_id',
  getUnitDetailsAuctionCancelApplication: (q: Application.Id) =>
    gen(routes.unitDetailsAuctionCancelApplication, q),
  // UNIT -> OTHER
  unitApplications: 'applications',
  unitInvitedUsers: 'invited-users',
  unitDetailsAuditLog: 'audit-log',
  unitDetailsLeases: 'leases',
  // #endregion

  // #region USERS
  users: '/owner/users',
  // #endregion

  // #region USER
  userDetails: '/owner/users/:user_id',
  getUserDetails: (q: User.Id) => gen(routes.userDetails, q),
  // USER DETAILS -> OVERVIEW
  userDetailsOverview: 'overview',
  userDetailsViewPhotoId: 'view-photo-id',
  userDetailsCreditScoreDownload: 'credit-score-download',
  // USER DETAILS -> OWNERS
  userDetailsOwners: 'owners',
  // USER DETAILS -> AGENT UNITS
  userDetailsAgentUnits: 'agent-units',
  userDetailsAgentUnitsEdit: 'edit/:unit_id',
  getUserDetailsAgentUnitsEdit: (q: Unit.Id) => gen(routes.userDetailsAgentUnitsEdit, q),
  // USER DETAILS -> INVITED TO UNITS
  userDetailsInvitedToUnits: 'invited-to-units',
  // USER DETAILS -> APPLICATIONS
  userDetailsApplications: 'applications',
  // USER DETAILS -> DOCUMENTS
  userDetailsDocuments: 'documents',
  userDetailsDocumentsUpload: 'upload',
  userDetailsDocumentsView: 'view/:document_id',
  getUserDetailsDocumentsView: (q: UserDocument.Id) => gen(routes.userDetailsDocumentsView, q),
  userDetailsDocumentsEdit: 'edit/:document_id',
  getUserDetailsDocumentsEdit: (q: UserDocument.Id) => gen(routes.userDetailsDocumentsEdit, q),
  // USER DETAILS -> LINKED ACCOUNTS
  userDetailsLinkedAccounts: 'linked-accounts',
  // USER DETAILS -> LEASES
  userDetailsLeases: 'leases',
  userDetailsLeasesEdit: 'edit/:lease_id',
  getUserDetailsLeasesEdit: (q: Lease.Id) => gen(routes.userDetailsLeasesEdit, q),
  userDetailsLeasesDelete: 'delete/:lease_id',
  getUserDetailsLeasesDelete: (q: Lease.Id) => gen(routes.userDetailsLeasesDelete, q),
  // #endregion
} as const

const withSearchParams = (path: string, query?: Record<string, string>) =>
  path + (query ? '?' + new URLSearchParams(query).toString() : '')

export const ownerRoutes = routes
