import { ComponentProps, FC } from 'react'
import { Outlet } from 'ui'
import { SignedIn } from './signed-in'

type Props = Omit<ComponentProps<typeof SignedIn>, 'children'>

export const SignedInRoute: FC<Props> = (props) => {
  return (
    <SignedIn {...props} hideWhileLoading>
      <Outlet />
    </SignedIn>
  )
}
