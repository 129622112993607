import { User, api, UserRole, AdminUser } from 'api/admin'
import { adminRoutes } from 'const/admin-routes'
import { LoaderFunctionArgs, Params, redirect } from 'react-router-dom'
import { Access, validateRoleAccess } from './access'

export function authorized<Data extends {}, P extends string = string>(
  access: Access,
  loader?: (params: { user: User; request: Request; params: Params<P> }) => Promise<Data> | Data,
  config?: { ownerVersion?: boolean },
) {
  return async (params: LoaderFunctionArgs) => {
    const user = await api.user.currentUser()
    if (!user) {
      throw redirect(withNextPath(adminRoutes.login, params.request.url))
    }
    if (!hasAdminAccess(user)) {
      throw new Error(`You do not have access to this site.`)
    }
    if (shouldAcceptTOS(user)) {
      throw redirect(withNextPath(adminRoutes.terms, params.request.url))
    }
    if (config?.ownerVersion && !AdminUser.hasRoleAdmin(user)) {
      throw redirect(getOwnerVersion(params.request.url))
    }
    if (!validateRoleAccess(user, access)) {
      throw new Error(`You do not have access to this page. ${access} permission required.`)
    }
    if (!loader) return {}
    return (await loader({ ...params, user })) ?? {}
  }
}

const AVAILABLE_FOR = [UserRole.Agent, UserRole.Owner, UserRole.Admin, UserRole.Support]
export const hasAdminAccess = (user: User) => {
  return AVAILABLE_FOR.some((role) => user.roles?.includes(role))
}

export const shouldAcceptTOS = (user: AdminUser) => {
  return AdminUser.hasRoleAdmin(user) ? false : !user.terms_accepted_at
}

const withNextPath = (target: string, url: string) => {
  const { pathname, search } = new URL(url)
  const searchParams = new URLSearchParams({ next: [pathname, search].join('?') })
  return `${target}?${searchParams.toString()}`
}

const getOwnerVersion = (link: string) => {
  const url = new URL(link)
  url.pathname = `/owner` + url.pathname
  return url.toString()
}
