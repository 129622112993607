import { useUser } from 'components/use-user'
import { UserName } from 'components/user-name'
import { AccountIcon } from 'icons'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { Avatar, Dropdown } from 'ui'
import { cn } from 'utils'
import { useFirebaseUser } from 'web-auth'
import styles from './user-menu.module.css'

interface Props {
  className?: string
  accountLinkTo: To
}

export const UserMenu: FC<Props> = ({ className, accountLinkTo }) => {
  const [user] = useUser()
  const firebaseUser = useFirebaseUser()

  if (!user) return null
  return (
    <Dropdown.Container>
      <Dropdown.Toggle theme="link" className={cn(styles.toggle, className)}>
        <UserName className={styles.name} />
        <Avatar src={firebaseUser?.photoURL} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.menu}>
        <Dropdown.Item to={accountLinkTo} title="Account" icon={<AccountIcon />} />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
