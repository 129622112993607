import { AdminPage } from 'components/page'
import { useUser } from 'components/use-user'
import { LinkButton } from 'ui'
import { getHomeRoute } from 'utils/get-home-route'
import styles from './forbidden.module.scss'

export default function ForbiddenPage() {
  const [user] = useUser()
  return (
    <AdminPage className={styles.page}>
      <h1>Access denied</h1>
      <p>You do not have the necessary permission required to access this view</p>
      <LinkButton theme="primary" to={getHomeRoute(user)}>
        Back to Home Screen
      </LinkButton>
    </AdminPage>
  )
}
