import { UserRole } from 'api/admin'
import { FC, ReactNode } from 'react'
import { Role as _Role } from 'utils/access'
import { SignedIn } from './signed-in'

type Key = `${keyof typeof _Role}`

type Props = Partial<Record<Key, boolean>> & {
  children: ReactNode | ReactNode[]
}

export const Role: FC<Props> = ({ children, Admin, Agent, Guarantor, Owner }) => {
  const roles = [
    Admin && UserRole.Admin,
    Agent && UserRole.Agent,
    Guarantor && UserRole.Guarantor,
    Owner && UserRole.Owner,
  ].filter(Boolean) as UserRole[]
  return (
    <SignedIn out={false} roles={roles} hideWhileLoading>
      {children}
    </SignedIn>
  )
}
