import { FC, ReactNode } from 'react'
import { Access } from 'utils/access'
import { SignedIn } from './signed-in'

interface Props {
  children: ReactNode | ReactNode[]
}
type Key = `${keyof typeof Access}`
type Type = Record<Key, FC<Props>>

export const Permission: Type = Object.fromEntries(
  Object.entries(Access).map(([key, access]) => {
    const C: FC<Props> = ({ children }) => (
      <SignedIn out={false} access={access} hideWhileLoading>
        {children}
      </SignedIn>
    )
    C.displayName = `Permission.${key}`
    return [key, C]
  }),
) as Type
