import { AdminUser, CurrentUser, UserRole } from 'api/admin'

export const Access = {
  Admin: 'admin',
  Any: '*',
  Application: 'application',
  ApplicationApprove: 'application.approve',
  ApplicationEdit: 'application.edit',
  ApplicationReport: 'application.report',
  ApplicationChat: 'application.chat',
  Chat: 'chat',
  ChatList: 'chat.list',
  ChatCreate: 'chat.create',
  ChatDelete: 'chat.delete',
  Dashboard: 'dashboard',
  Invitation: 'invitation',
  Lease: 'lease',
  LeaseAudit: 'lease.audit',
  LeaseCreate: 'lease.create',
  Owner: 'owner',
  OwnerAgent: 'owner.agent',
  OwnerAgentManage: 'owner.agent.manage',
  OwnerApplications: 'owner.application',
  OwnerApplicationApprove: 'owner.application.approve',
  OwnerApplicationReport: 'owner.application.report',
  OwnerApplicationEdit: 'owner.application.edit',
  OwnerApplicationDelete: 'owner.application.delete',
  OwnerChecklistEdit: 'owner.checklist.edit',
  OwnerDashboard: 'owner.dashboard',
  OwnerLease: 'owner.lease',
  OwnerLeaseCreate: 'owner.lease.create',
  OwnerLeaseLock: 'owner.lease.lock',
  OwnerLeaseEdit: 'owner.lease.edit',
  OwnerLeaseRegenerateContract: 'owner.lease.regenerate-contract',
  OwnerLeaseDelete: 'owner.lease.delete',
  OwnerLeaseLedgerAdd: 'owner.lease.ledger.add',
  OwnerLeaseLedgerEdit: 'owner.lease.ledger.edit',
  OwnerLeaseLedgerDelete: 'owner.lease.ledger.delete',
  OwnerMy: 'owner.my',
  OwnerPaymentAccount: 'owner.payment-account',
  OwnerPdf: 'owner.pdf',
  OwnerLedger: 'owner.ledger',
  OwnerProperties: 'owner.properties',
  OwnerPropertyCreate: 'owner.property.create',
  OwnerPropertyUpdate: 'owner.property.update',
  OwnerPropertyAudit: 'owner.property.audit',
  OwnerTemplate: 'owner.template',
  OwnerTemplateCreate: 'owner.template.create',
  OwnerTemplateDelete: 'owner.template.delete',
  UnitAuction: 'unit.auction',
  UnitAuctionCreate: 'unit.auction.create',
  UnitAuctionEdit: 'unit.auction.edit',
  UnitAuctionArchive: 'unit.auction.archive',
  UnitAuctionDelete: 'unit.auction.delete',

  OwnerUnitCreate: 'owner.unit.create',
  OwnerUnits: 'owner.units',
  OwnerUnitUpdate: 'owner.unit.update',
  OwnerUnitAudit: 'owner.unit.audit',
  OwnerUser: 'owner.user',
  Payments: 'payments',
  PlaidRequests: 'requests',
  Property: 'property',
  PropertyAudit: 'property.audit',
  PropertyManager: 'property-manager',
  Support: 'support',
  Template: 'template',
  Unit: 'unit',
  UnitAudit: 'unit.audit',
  User: 'user',
  UserAudit: 'user.audit',
} as const

export const Role = {
  Admin: UserRole.Admin,
  Owner: UserRole.Owner,
  Agent: UserRole.Agent,
  Guarantor: UserRole.Guarantor,
}

type Keys = keyof typeof Access
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Access = (typeof Access)[Keys]

export const validateRoleAccess = (user: CurrentUser, access: `${Access}`): boolean | string => {
  if (!user) throw new Error('Unable to validate access: user is not defined')

  switch (access) {
    case Access.Any:
    case Access.Invitation:
    case Access.OwnerApplications:
    case Access.OwnerApplicationEdit:
    case Access.OwnerApplicationReport:
    case Access.OwnerChecklistEdit:
    case Access.OwnerDashboard:
    case Access.OwnerLease:
    case Access.OwnerLedger:
    case Access.OwnerProperties:
    case Access.OwnerPropertyCreate:
    case Access.OwnerPropertyUpdate:
    case Access.OwnerUnitCreate:
    case Access.OwnerUnits:
    case Access.OwnerUnitUpdate:
    case Access.OwnerUser:
      return (
        AdminUser.hasRoleAdmin(user) || AdminUser.hasRoleOwner(user) || AdminUser.hasRoleAgent(user)
      )

    case Access.OwnerAgent:
    case Access.OwnerAgentManage:
    case Access.OwnerApplicationApprove:
    case Access.OwnerLeaseCreate:
    case Access.OwnerLeaseLedgerAdd:
    case Access.OwnerLeaseLedgerEdit:
    case Access.OwnerLeaseLock:
    case Access.OwnerLeaseRegenerateContract:
    case Access.OwnerPaymentAccount:
    case Access.OwnerTemplate:
    case Access.OwnerTemplateCreate:
    case Access.OwnerUnitAudit:
      return AdminUser.hasRoleOwner(user) || AdminUser.hasRoleAdmin(user)

    case Access.OwnerMy:
      return AdminUser.hasRoleOwner(user)

    case Access.Admin:
    case Access.Application:
    case Access.ApplicationApprove:
    case Access.ApplicationEdit:
    case Access.ApplicationReport:
    case Access.Dashboard:
    case Access.Lease:
    case Access.LeaseCreate:
    case Access.Owner:
    case Access.OwnerLeaseDelete:
    case Access.OwnerLeaseEdit:
    case Access.OwnerLeaseLedgerDelete:
    case Access.OwnerPdf:
    case Access.OwnerPropertyAudit:
    case Access.OwnerTemplateDelete:
    case Access.Payments:
    case Access.PlaidRequests:
    case Access.Property:
    case Access.PropertyAudit:
    case Access.PropertyManager:
    case Access.Template:
    case Access.Unit:
    case Access.UnitAudit:
    case Access.UnitAuction:
    case Access.UnitAuctionArchive:
    case Access.UnitAuctionCreate:
    case Access.UnitAuctionEdit:
    case Access.UnitAuctionDelete:
    case Access.User:
    case Access.UserAudit:
      return AdminUser.hasRoleAdmin(user)

    case Access.Support:
    case Access.ChatDelete:
      return AdminUser.hasRoleSupport(user)

    case Access.OwnerApplicationDelete:
    case Access.LeaseAudit:
    case Access.ApplicationChat:
    case Access.Chat:
    case Access.ChatCreate:
    case Access.ChatList:
    default:
      return false
  }
}
