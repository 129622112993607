import { SupportNotificationBadge } from '@rello/chat'
import { useUser } from 'components/use-user'
import { IconMenu } from 'icons'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { Dropdown } from 'ui'
import { cn } from 'utils'
import { MobileMenuContent } from './content'
import styles from './mobile-menu.module.scss'

interface Props {
  className?: string
  logoLinkTo?: To
}

export const MobileMenu: FC<Props> = ({ className, logoLinkTo }) => {
  const [user] = useUser()

  return (
    <Dropdown.Container>
      <Dropdown.Toggle className={cn(styles.menu, styles.toggle, className)}>
        <IconMenu />
        {user && <SupportNotificationBadge className={styles.dot} />}
      </Dropdown.Toggle>
      <MobileMenuContent className={styles.menu} logoLinkTo={logoLinkTo} />
    </Dropdown.Container>
  )
}
