export const UNIT_PATHS = {
  unitGeneral: 'general',
  unitLocation: 'location',
  unitListing: 'listing',
  unitAmenities: 'amenities',
  unitPhotos: 'photos',
  unitDescription: 'description',
  unitLease: 'lease',
  unitExtend: 'extend',
  unitPublish: 'publish',
} as const
