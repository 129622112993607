import { FC, HTMLProps } from 'react'
import { cn } from 'utils'
import styles from './admin-page.module.scss'

type PageProps = HTMLProps<HTMLDivElement> & {
  noNav?: boolean
}

export const AdminPage: FC<PageProps> = ({ className, children, noNav, ...props }) => {
  return (
    <div className={cn(styles.main, noNav && styles.noNav, className)} {...props}>
      {children}
    </div>
  )
}
