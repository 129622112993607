import { AdminPage as Page } from './admin-page'
import { AdminPageActions as Actions } from './admin-page-actions'
import { AdminPageFilter as Filter } from './admin-page-filter'
import { AdminPageHeader as Header } from './admin-page-header'
export * from './navigation-container'

export const AdminPage = Object.assign(Page, {
  Header,
  Actions,
  Filter,
})
