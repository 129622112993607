import { AdminPage } from 'components/page'
import { FC } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Button, Description } from 'ui'
import styles from './error-page.module.scss'

interface Props {}
export const ErrorPage: FC<Props> = (props) => {
  const error = useRouteError()
  const isRouteResponse = isRouteErrorResponse(error)
  const message =
    (isRouteResponse && error.data?.message) || (error as Error)?.message || 'Unknown error'

  return (
    <AdminPage>
      <h1>Oops</h1>
      <Description error>
        <ul>
          <li className={styles.content}>
            {isRouteResponse && (
              <>
                <h2>{error.status}</h2>
                <p>{error.statusText}</p>
              </>
            )}
            <p>{message}</p>
            <Button
              onClick={() => window?.location?.reload()}
              theme="warn"
              className={styles.button}
            >
              Refresh
            </Button>
          </li>
        </ul>
      </Description>
    </AdminPage>
  )
}
