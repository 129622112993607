import { SupportNotificationBadge } from '@rello/chat'
import { Application, Property, PropertyManager, Unit } from 'api/admin'
import { Permission, Role } from 'components/signed-in'
import { useUser } from 'components/use-user'
import { adminRoutes } from 'const/admin-routes'
import { ownerRoutes } from 'const/owner-routes'
import { FC } from 'react'
import { Dropdown } from 'ui'
import { cn } from 'utils'
import styles from './nav.module.scss'

interface Props {
  className?: string
}
export const Nav: FC<Props> = ({ className }) => {
  const [user] = useUser()
  return (
    <nav role="menu" className={cn(styles.nav, className)}>
      <Permission.Support>
        <ul className={styles.list}>
          <li className={styles.withBadge}>
            <Dropdown.Item
              title="Support"
              to={adminRoutes.support}
              icon={user ? <SupportNotificationBadge className={styles.dot} /> : undefined}
            />
          </li>
        </ul>
      </Permission.Support>

      <ul className={styles.list}>
        <Permission.OwnerDashboard>
          <li>
            <Dropdown.Item title="Dashboard" to={ownerRoutes.dashboard} />
          </li>
        </Permission.OwnerDashboard>
        <Permission.OwnerApplications>
          <li>
            <Dropdown.Item title={Application.Plural} to={ownerRoutes.applications} />
          </li>
        </Permission.OwnerApplications>
        <Permission.Invitation>
          <li>
            <Dropdown.Item title="Invitations" to={ownerRoutes.invitations} />
          </li>
        </Permission.Invitation>
        <Permission.OwnerLease>
          <li>
            <Dropdown.Item title="Leases" to={ownerRoutes.leases} />
          </li>
        </Permission.OwnerLease>
        <Permission.OwnerLedger>
          <li>
            <Dropdown.Item title="Payments" to={ownerRoutes.payments} />
          </li>
        </Permission.OwnerLedger>
        <Permission.OwnerPaymentAccount>
          <li>
            <Dropdown.Item title="Payment Accounts" to={ownerRoutes.paymentAccounts} />
          </li>
        </Permission.OwnerPaymentAccount>
        <Permission.OwnerProperties>
          <li>
            <Dropdown.Item title={Property.Plural} to={ownerRoutes.properties} />
          </li>
        </Permission.OwnerProperties>
        <Permission.OwnerTemplate>
          <li>
            <Dropdown.Item title="Templates" to={ownerRoutes.templates} />
          </li>
        </Permission.OwnerTemplate>
        <Permission.OwnerUnits>
          <li>
            <Dropdown.Item title={Unit.Plural} to={ownerRoutes.units} />
          </li>
        </Permission.OwnerUnits>
        <Permission.OwnerUser>
          <li>
            <Dropdown.Item title="Users" to={ownerRoutes.users} />
          </li>
        </Permission.OwnerUser>
      </ul>
      <Role Admin>
        <ul className={styles.list}>
          <Permission.Dashboard>
            <li>
              <Dropdown.Item title="Dashboard" to={adminRoutes.dashboard} />
            </li>
          </Permission.Dashboard>
          <Permission.Application>
            <li>
              <Dropdown.Item title={Application.Plural} to={adminRoutes.applications} />
            </li>
          </Permission.Application>
          <Permission.Invitation>
            <li>
              <Dropdown.Item title="Invitations" to={adminRoutes.invitations} />
            </li>
          </Permission.Invitation>
          <Permission.Lease>
            <li>
              <Dropdown.Item title="Leases" to={adminRoutes.leases} />
            </li>
          </Permission.Lease>
          <Permission.Owner>
            <li>
              <Dropdown.Item title="Owners" to={adminRoutes.owners} />
            </li>
          </Permission.Owner>
          <Permission.Payments>
            <li>
              <Dropdown.Item title="Payments" to={adminRoutes.payments} />
            </li>
          </Permission.Payments>
          <Permission.PlaidRequests>
            <li>
              <Dropdown.Item title="Plaid requests" to={adminRoutes.plaidRequests} />
            </li>
          </Permission.PlaidRequests>
          <Permission.Property>
            <li>
              <Dropdown.Item title={Property.Plural} to={adminRoutes.properties} />
            </li>
          </Permission.Property>
          <Permission.PropertyManager>
            <li>
              <Dropdown.Item title={PropertyManager.Plural} to={adminRoutes.propertyManagers} />
            </li>
          </Permission.PropertyManager>
          <Permission.Template>
            <li>
              <Dropdown.Item title="Templates" to={adminRoutes.templates} />
            </li>
          </Permission.Template>
          <Permission.Unit>
            <li>
              <Dropdown.Item title={Unit.Plural} to={adminRoutes.units} />
            </li>
          </Permission.Unit>
          <Permission.User>
            <li>
              <Dropdown.Item title="Users" to={adminRoutes.users} />
            </li>
          </Permission.User>
        </ul>
      </Role>
    </nav>
  )
}
