import { AsideNav } from 'components/nav'
import { FC, ReactNode } from 'react'
import { AnimatedOutlet, MediaQuery } from 'ui'
import styles from './navigation-container.module.scss'

interface Props {
  navComponent?: typeof AsideNav
  children?: ReactNode
}

export const NavigationContainer: FC<Props> = ({ navComponent: Nav = AsideNav, children }) => {
  return (
    <div className={styles.container}>
      <MediaQuery.Desktop>
        <Nav className={styles.aside} />
      </MediaQuery.Desktop>
      <main className={styles.main}>
        {children ?? (
          <AnimatedOutlet className={styles.content} ignoreAnscestorsAfter={1} ignoreSamePath />
        )}
      </main>
    </div>
  )
}
