import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import styles from './admin-page.module.scss'

export const AdminPageFilter: FC<ComponentProps<'div'>> = ({ className, children, ...props }) => {
  return (
    <div {...props} className={cn(styles.filter, className)}>
      {children}
    </div>
  )
}
