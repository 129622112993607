import { ComponentProps, FC, ReactNode } from 'react'
import { cn } from 'utils'
import { AdminPageActions } from './admin-page-actions'
import { AdminPageFilter } from './admin-page-filter'
import styles from './admin-page.module.scss'

type Props = Pick<ComponentProps<'div'>, 'className' | 'children'> & {
  title: string
  actions?: ReactNode
  filter?: ReactNode
  results?: ReactNode
}

export const AdminPageHeader: FC<Props> = ({
  className,
  children,
  title,
  actions,
  filter,
  results,
  ...props
}) => {
  return (
    <header className={cn(styles.header, className)} {...props}>
      <h1>{title}</h1>
      {actions && <AdminPageActions>{actions}</AdminPageActions>}
      {filter && <AdminPageFilter>{filter}</AdminPageFilter>}
      {children}
    </header>
  )
}
