import { Nav } from 'components/nav'
import { UserName } from 'components/user-name'
import { adminRoutes } from 'const/admin-routes'
import { Variants } from 'framer-motion'
import { AccountIcon, IconClose } from 'icons'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { Dropdown, useDropdownContext, Portal, Logo } from 'ui'
import { cn } from 'utils'
import styles from './content.module.scss'

interface Props {
  className?: string
  logoLinkTo?: To
}

const variants: Variants = {
  hidden: {
    translateX: '-100vw',
    transition: {
      delay: 0.3,
    },
  },
  show: {
    translateX: 0,
  },
}

export const MobileMenuContent: FC<Props> = ({ className, logoLinkTo }) => {
  const { opened } = useDropdownContext() ?? {}
  return (
    <Portal scrollLock={opened}>
      <Dropdown.Menu
        className={cn(styles.nav, className)}
        variants={variants}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <header className={styles.header}>
          <Logo linkTo={logoLinkTo} className={styles.logo} />
          <Dropdown.Toggle className={styles.close}>
            <IconClose />
          </Dropdown.Toggle>
        </header>

        <div className={styles.userpick}>
          <div className={styles.label}>You are logged in as</div>
          <UserName className={styles.name} full />
        </div>

        <div className={styles.userMenu}>
          <Dropdown.Item to={adminRoutes.account} title="Account" icon={<AccountIcon />} />
        </div>

        <hr />
        <Nav className={styles.menu} />
      </Dropdown.Menu>
    </Portal>
  )
}
