import { IconDoubleArrowLeft, IconDoubleArrowRight } from 'icons'
import { FC } from 'react'
import { useLocalStorage, IconButton } from 'ui'
import { cn } from 'utils'
import styles from './aside-nav.module.scss'
import { Nav } from './nav'

interface Props {
  className?: string
}
export const AsideNav: FC<Props> = ({ className }) => {
  const [opened, setOpened] = useLocalStorage('admin-nav-opened', true)
  return (
    <aside className={cn(styles.aside, opened ? styles.opened : styles.closed, className)}>
      <IconButton
        className={styles.switch}
        onClick={() => setOpened((opened) => !opened)}
        title={`${opened ? 'Hide' : 'Show'} Menu`}
      >
        {opened ? <IconDoubleArrowLeft /> : <IconDoubleArrowRight />}
      </IconButton>
      <Nav className={styles.nav} />
    </aside>
  )
}
