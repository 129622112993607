import { useUser } from 'components/use-user'
import { FC } from 'react'
import { getFullName } from 'utils/full-name'

interface Props {
  className?: string
  full?: boolean
}

export const UserName: FC<Props> = ({ className, full }) => {
  const [user] = useUser()
  const name = user ? (full ? getFullName(user) : user.first_name) : null

  return className ? <span className={className}>{name}</span> : <>{name}</>
}
