import { useEffect } from 'react'

export const useReload = (active?: boolean) => {
  useEffect(() => {
    if (!active) return
    const reloadedAt = sessionStorage.getItem('reloaded_at')
    if (reloadedAt && new Date().getTime() - parseInt(reloadedAt, 10) < 1000) {
      sessionStorage.removeItem('reloaded_at')
    } else {
      sessionStorage.setItem('reloaded_at', new Date().getTime().toString())
      window.location.reload()
    }
  }, [active])
}
