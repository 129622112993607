import { AdminPage } from 'components/page'
import { adminRoutes } from 'const/admin-routes'
import { LinkButton } from 'ui'
import styles from './404.module.scss'

export default function NotFoundPage() {
  return (
    <AdminPage className={styles.page}>
      <h1>Page not Found</h1>
      <LinkButton theme="primary" to={adminRoutes.welcome}>
        Back to Home Screen
      </LinkButton>
    </AdminPage>
  )
}
